<template>
  <v-container fluid>
    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="sendEmailDialog" max-width="500">
      <v-card>
        <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark>
          Send HRDC Email To Client
        </v-toolbar>

        <div class="pa-4">Please confirm that you wish to send this email</div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalSendHrdcEmailToClient()"
          >
            Submit
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="sendEmailDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--BOC:[contents]-->
    <v-card>
      <div>
        <v-toolbar color="#063058">
          <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title v-if="!loadingDialog">
            <div class="d-flex align-center text-white">
              {{ model.add.name }}
            </div>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <span v-if="!btnDisabled">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="hidden-xs-only"
                  color="white"
                  :loading="apiSubmit.isLoading"
                  @click="sendEmailDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-email-arrow-right
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ model.add.name }}</span>
            </v-tooltip>
          </span>
        </v-toolbar>
      </div>

      <v-spacer></v-spacer>

      <div>
        <v-row
          class="d-flex justify-space-between"
          cols="12"
          lg="12"
          md="12"
          sm="12"
        >
          <v-col cols="6" style="border-right: 1px solid lightgrey">
            <v-card
              class="pa-2 ma-2"
              :style="{ backgroundColor: hrdcData.HRDC_CARD_COLOR }"
              outlined
            >
              <!-- Email subject -->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-text-field
                  dense
                  filled
                  label="Email Subject"
                  v-model="formData.emailSubject"
                  :error-messages="errorField.emailSubject"
                  :disabled="inputDisabled"
                ></v-text-field>
              </v-col>

              <!-- TO -->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-combobox
                  dense
                  filled
                  label="TO Recipient"
                  v-model="formData.toEmail"
                  :error-messages="errorField.toEmail"
                  :hide-selected="true"
                  multiple
                  small-chips
                  :disabled="inputDisabled"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      {{ data.item }}
                      <v-icon small @click="data.parent.selectItem(data.item)">
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>

                  <template v-slot:append>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"> mdi-information </v-icon>
                      </template>
                      Press <kbd>enter/tab</kbd> to insert multiple email
                    </v-tooltip>
                  </template></v-combobox
                >
              </v-col>

              <!-- CC -->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-combobox
                  dense
                  filled
                  label="CC Recipient"
                  v-model="formData.ccEmail"
                  :error-messages="errorField.ccEmail"
                  :hide-selected="true"
                  multiple
                  small-chips
                  :disabled="inputDisabled"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      {{ data.item }}
                      <v-icon small @click="data.parent.selectItem(data.item)">
                        $delete
                      </v-icon>
                    </v-chip>
                  </template>

                  <template v-slot:append>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"> mdi-information </v-icon>
                      </template>
                      Press <kbd>enter/tab</kbd> to insert multiple email
                    </v-tooltip>
                  </template></v-combobox
                >
              </v-col>

              <!-- Remarks -->
              <v-col class="d-flex pb-0" cols="12" sm="12" md="12">
                <v-textarea
                  dense
                  filled
                  auto-grow
                  label="Remarks"
                  v-model="formData.remarks"
                  :error-messages="errorField.remarks"
                  :disabled="inputDisabled"
                ></v-textarea>
              </v-col>

              <!-- Send Email Options -->
              <v-col class="d-flex pt-0" cols="12" sm="12" md="12">
                <v-radio-group
                  v-model="formData.byPassEmailBool"
                  column
                  :disabled="inputDisabled"
                >
                  <template v-slot:label>
                    <div><b>Send Email Options:</b></div>
                  </template>
                  <v-radio
                    label="Send Email"
                    color="primary"
                    value="true"
                  ></v-radio>
                  <v-radio
                    label="Do Not Send Email"
                    color="error"
                    value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>

              <!-- Auto Capture Grant Approval Email -->
              <v-col class="d-flex pt-0" cols="12" sm="12" md="12">
                <v-radio-group
                  v-model="formData.autoCaptureGrantApprovalEmail"
                  column
                  :disabled="inputDisabled"
                >
                  <template v-slot:label>
                    <div><b>Auto Capture Grant Approval Email:</b></div>
                  </template>
                  <v-radio
                    label="Capture"
                    color="primary"
                    value="true"
                  ></v-radio>
                  <v-radio
                    label="Do Not Capture"
                    color="error"
                    value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-card>
          </v-col>

          <v-col cols="6">
            <v-card
              class="pa-2 ma-2"
              :style="{ backgroundColor: hrdcData.HRDC_CARD_COLOR }"
              outlined
            >
              <!-- Confirmation Letter Company Name -->
              <v-col class="d-flex pb-0" cols="12" sm="12" md="12">
                <v-textarea
                  rows="2"
                  dense
                  filled
                  auto-grow
                  label="Confirmation Letter Company Name"
                  v-model.trim="formData.oriCompanyName"
                  :error-messages="errorField.oriCompanyName"
                  @input="autoEmbedData()"
                  :disabled="inputDisabled"
                ></v-textarea>
              </v-col>

              <!-- Company Address -->
              <v-col class="d-flex py-0" cols="12" sm="12" md="12">
                <v-textarea
                  rows="4"
                  dense
                  filled
                  auto-grow
                  label="Company Address"
                  v-model.trim="formData.companyAddress"
                  :error-messages="errorField.companyAddress"
                  @input="autoEmbedData()"
                  :disabled="inputDisabled"
                ></v-textarea>
              </v-col>

              <!-- taxPOD Pricing -->
              <v-col class="d-flex py-0" cols="12" sm="12" md="12">
                <v-text-field
                  disabled
                  dense
                  filled
                  auto-grow
                  label="Claim Amount"
                  v-model="formData.totalPrice"
                  :error-messages="errorField.totalPrice"
                  @input="autoEmbedData()"
                ></v-text-field>
              </v-col>

              <!-- Subscription Date Range -->
              <v-col class="d-flex py-0" cols="12" sm="12" md="12">
                <v-col cols="6" class="pa-0" v-if="toggleSubscriptionDate">
                  <v-select
                    v-model="formData.subscriptionDateRange"
                    :error-messages="errorField.subscriptionDateRange"
                    :items="dynamicSubscriptionDateItems"
                    filled
                    dense
                    class="apply-grant-hint"
                    label="Subscription Date (YYYY-MM-DD)"
                    :persistent-hint="displayApplyGrantHint"
                    :hint="applyGrantHint"
                    @change="changeSubscriptionDate()"
                    :menu-props="{
                      bottom: true,
                      offsetY: true,
                    }"
                  >
                    <template v-slot:prepend-inner>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            v-on:click="toggleSubscriptionDateInput()"
                          >
                            mdi-form-dropdown
                          </v-icon>
                        </template>
                        Change to calander view
                      </v-tooltip>
                    </template>
                  </v-select>
                </v-col>

                <v-col
                  cols="6"
                  class="pa-0"
                  v-if="!inputDisabled && !toggleSubscriptionDate"
                >
                  <v-menu
                    ref="subscriptionMenu"
                    v-model="formData.subscriptionMenu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        filled
                        v-model="subscriptionDate"
                        label="Subscription Date (YYYY-MM-DD)"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errorField.subscriptionDateRange"
                      >
                        <template v-slot:prepend-inner>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltipOn }">
                              <v-icon
                                v-on:click="toggleSubscriptionDateInput()"
                                v-on="tooltipOn"
                                >mdi-calendar</v-icon
                              >
                            </template>
                            <span>Change to option view</span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      @change="autoEmbedData()"
                      range
                      v-model="formData.subscriptionDateRange"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="formData.subscriptionMenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.subscriptionMenu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6" class="pa-0" v-if="inputDisabled">
                  <v-select
                    v-model="formData.subscriptionDateRange"
                    :error-messages="errorField.subscriptionDateRange"
                    :items="dynamicSubscriptionDateItems"
                    filled
                    dense
                    class="apply-grant-hint"
                    label="Subscription Date (YYYY-MM-DD)"
                    :persistent-hint="displayApplyGrantHint"
                    :hint="applyGrantHint"
                    @change="changeSubscriptionDate()"
                    :menu-props="{
                      bottom: true,
                      offsetY: true,
                    }"
                  ></v-select>
                </v-col>

                <v-col cols="6" class="py-0 pr-0">
                  <v-text-field
                    type="number"
                    dense
                    filled
                    label="JD14 Company Name & Address Font Size"
                    v-model="formData.companyAddressFontSize"
                    :error-messages="errorField.companyAddressFontSize"
                    @change="autoEmbedData()"
                    :disabled="inputDisabled"
                  ></v-text-field>
                </v-col>
              </v-col>
            </v-card>

            <hr />

            <v-card
              class="pa-2 ma-2"
              :style="{ backgroundColor: hrdcData.HRDC_CARD_COLOR }"
              outlined
            >
              <!--Confirmation Letter-->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-col cols="9" class="pa-0">
                  <div
                    v-cloak
                    @drop.prevent="addDropFile($event, 'confirmationLetterUrl')"
                    @dragover.prevent
                    class="w-100"
                  >
                    <v-file-input
                      :prepend-icon="null"
                      prepend-inner-icon="mdi-paperclip"
                      dense
                      filled
                      small-chips
                      label="Confirmation Letter"
                      accept=".pdf"
                      @change="scanPdf($event)"
                      v-model="formData.confirmationLetterUrl"
                      :error-messages="errorField.confirmationLetterUrl"
                      :truncate-length="1000"
                      :disabled="inputDisabled"
                    ></v-file-input>
                  </div>
                </v-col>
                <v-col class="pt-0" cols="3">
                  <v-btn
                    x-large
                    color="#063058"
                    outlined
                    @click="previewAutoEmbedPdf('confirmationLetterUrl')"
                  >
                    Preview
                  </v-btn>
                </v-col>
              </v-col>

              <!--Send JD14 File-->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-col cols="9" class="pa-0">
                  <div
                    v-cloak
                    @drop.prevent="addDropFile($event, 'sendJd14Url')"
                    @dragover.prevent
                    class="w-100"
                  >
                    <v-file-input
                      :prepend-icon="null"
                      prepend-inner-icon="mdi-paperclip"
                      dense
                      filled
                      small-chips
                      label="JD14 Form"
                      accept=".pdf"
                      v-model="formData.sendJd14Url"
                      :error-messages="errorField.sendJd14Url"
                      :truncate-length="1000"
                      :disabled="inputDisabled"
                    ></v-file-input>
                  </div>
                </v-col>
                <v-col class="pt-0" cols="3">
                  <v-btn
                    x-large
                    color="#063058"
                    outlined
                    @click="previewAutoEmbedPdf('sendJd14Url')"
                  >
                    Preview
                  </v-btn>
                </v-col>
              </v-col>

              <!--Send T3 File-->
              <v-col class="d-flex" cols="12" sm="12" md="12">
                <v-col cols="9" class="pa-0">
                  <div
                    v-cloak
                    @drop.prevent="addDropFile($event, 'sendT3Url')"
                    @dragover.prevent
                    class="w-100"
                  >
                    <v-file-input
                      :prepend-icon="null"
                      prepend-inner-icon="mdi-paperclip"
                      dense
                      filled
                      small-chips
                      label="T3 Form"
                      accept=".pdf"
                      v-model="formData.sendT3Url"
                      :error-messages="errorField.sendT3Url"
                      :truncate-length="1000"
                      :disabled="inputDisabled"
                    ></v-file-input>
                  </div>
                </v-col>
                <v-col class="pt-0" cols="3">
                  <v-btn
                    x-large
                    outlined
                    color="#063058"
                    @click="previewAutoEmbedPdf('sendT3Url')"
                  >
                    Preview
                  </v-btn>
                </v-col>
              </v-col>

              <!--Flyer (fake)-->
              <v-col class="d-flex" cols="9" sm="9" md="9">
                <div v-cloak class="w-100">
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    disabled
                    dense
                    filled
                    small-chips
                    label="Flyer"
                    v-model="formData.flyer"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>

              <!--Guide (fake)-->
              <v-col class="d-flex" cols="9" sm="9" md="9">
                <div v-cloak class="w-100">
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    disabled
                    dense
                    filled
                    small-chips
                    label="HRDC Claim Application Guideline"
                    v-model="formData.guide"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc/objects/globalData";

export default {
  mixins: [hrdcFunction],
  components: {},
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
    subscriptionDate() {
      if (typeof this.formData.subscriptionDateRange !== "string") {
        return this.formData.subscriptionDateRange.join(" - ");
      }

      return [];
    },
    inputDisabled() {
      if (this.isApplicationSendBefore && this.hrdcData.isSalesManagerRoles) {
        return true;
      }

      return (
        this.isApplicationSendBefore &&
        this.hrdcData.isSalespersonRoles &&
        this.auth.roles.length == 1
      );
    },
  },
  props: ["params"],
  data: () => ({
    apiSubmit: new Api(),
    apiScanPdfText: new Api(),
    apiGetHrdcEmailDetails: new Api(),
    apiAutoEmbedData: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    stages: null,
    alertError: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    formData: {
      applicationNo: null,
      toEmail: [],
      ccEmail: [],
      emailSubject: null,
      subscriptionDateRange: [],
      confirmationLetterUrl: null,
      sendJd14Url: null,
      sendT3Url: null,
      quotationMenu: false,
      subscriptionMenu: false,
      remarks: null,
      byPassEmailBool: "true",
      companyAddress: null,
      companyAddressFontSize: 10,
      oriCompanyName: null,
      autoCaptureGrantApprovalEmail: "true",
      claimAmount: null,
    },
    errorField: {
      applicationNo: null,
      toEmail: null,
      ccEmail: null,
      emailSubject: null,
      subscriptionDateRange: null,
      confirmationLetterUrl: null,
      sendJd14Url: null,
      sendT3Url: null,
      quotationMenu: null,
      subscriptionMenu: null,
      companyAddressFontSize: null,
      autoCaptureGrantApprovalEmail: null,
    },
    loadingDialog: false,
    sendEmailDialog: false,
    btnDisabled: false,
    editMode: false,
    isApplicationSendBefore: false,
    dynamicSubscriptionDateItems: [],
    totalEventDays: null,
    displayApplyGrantHint: false,
    applyGrantHint: null,
    toggleSubscriptionDate: true,
    nextStageId: null,
  }),
  async created() {
    this.showLoadingDialog();
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/email_client/create-email`,
        method: "post",
      },
      apiScanPdfText: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/email_client/scan-pdf`,
        method: "post",
      },
      apiGetHrdcEmailDetails: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/email_client/get-email-details`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
      apiAutoEmbedData: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/email_client/auto-embed-data`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    const currentStageId =
      this.hrdcData.stageIndex[this.$store.state.hrdc.data.stageId];

    this.hrdcData.rolesAssignation(this.auth.roles);

    this.checkFormAlreadyFilledGte(
      currentStageId,
      this.hrdcData.stageSlug.grantApprovalNoSubmitted
    );

    this.checkFormNotYetFilledLesser(
      currentStageId,
      this.hrdcData.stageSlug.applicationApproved,
      "Application haven't approve by taxPOD Admin"
    );

    // Get email data.
    this.apiGetHrdcEmailDetails.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;

        // In Edit Mode.
        if (status == true) {
          data.companyAddress = response.companyAddress;
          this.formData = this.transformEmailDetails(data);
          this.isApplicationSendBefore = true;

          this.hideLoadingDialog();
        } else {
          // First time sending.
          // Auto assigned recipient fields.
          const toEmailArr = [
            ...response.companyPicDetail.map((item) => item.picEmail),
          ];

          const ccEmailArr = [data.referByEmail, ...response.csData];

          this.formData.toEmail = toEmailArr.filter(
            (item, index, self) => item !== null && self.indexOf(item) == index
          );
          this.formData.ccEmail = ccEmailArr.filter(
            (item, index, self) => item !== null && self.indexOf(item) == index
          );
          this.formData.oriCompanyName = data.oriCompanyName;
          this.formData.emailSubject =
            `taxPOD Masterclass HRDC Grant Application - ${this.capitalizeFirstLetter(
              data.oriCompanyName
            )}`.toUpperCase();

          this.formData.companyAddress = response.companyAddress;
          this.formData.companyAddressFontSize = 10;
        }

        let version = null;
        let basePrice = null;
        basePrice = this.hrdcData.getTaxpodBasePricing(data.totalPrice);
        version = this.hrdcData.staticTaxpodFlyers.find(
          (item) => item.price == basePrice
        ).flyer;

        this.formData.flyer = {
          name: `taxPOD Masterclass Flyer ${version}.pdf`.trim(),
        };

        this.formData.guide = {
          name: `YYC taxPOD HRDC Grant and Claim Application Guideline ${version}.pdf`.trim(),
        };

        // Append claim amount.
        this.formData.claimAmount = data.totalPrice;
        this.formData.totalPrice =
          "RM " + Number(data.totalPrice).toLocaleString();

        if (!this.hrdcData.isHrdcAdminRoles) {
          this.alertError.push("You are not allowed to perform this action!");
          this.btnDisabled = true;
        }

        // Check grant email existing or not.
        const isHrdcGrantEmailExist = data.ApplicationAttachment.find(
          (item) => item.stageId == this.hrdcData.stagePriority.hrdcGrantEmail
        );

        /*
          Conditions for taxPOD salesperson to resend email to client on their own:
          1. Salesperson Role
          2. Email is being sent before (2nd time onwards)
          3. Grant is incomplete (red cross)
        */
        if (
          (this.hrdcData.isSalespersonRoles ||
            this.hrdcData.isSalesManagerRoles) &&
          this.isApplicationSendBefore &&
          !data.grantApprovalNo
        ) {
          this.toggleSubscriptionDate = false;
          if (
            (!isHrdcGrantEmailExist && !data.revertGrantStatus) ||
            this.$store.state.hrdc.data.stageId ==
              this.hrdcData.stagePriority.sentConfirmationEmailToClient
          ) {
            this.alertError = [];
            this.btnDisabled = false;
            this.editMode = false;
          }
        }

        if (currentStageId < this.hrdcData.stageSlug.clientRevertT3Form) {
          this.editMode = false;
        }

        this.nextStageId = this.$store.state.hrdc.data.stageId;
        if (
          this.$store.state.hrdc.data.stageId ==
          this.hrdcData.stagePriority.applicationApproved
        ) {
          this.nextStageId =
            this.hrdcData.stagePriority.sentConfirmationEmailToClient;
        }

        // If is salesperson/sales manager roles, cannot view HRDC Admin dynamic calendar view.
        if (
          this.hrdcData.isSalespersonRoles ||
          this.hrdcData.isSalesManagerRoles
        ) {
          this.toggleSubscriptionDate = false;
        }

        // Assign dynamic subscription period to allow TBOs select.
        this.dynamicSubscriptionDateItems =
          this.generateDynamicSubscriptionDate();

        // If status is new, continue to the normal flow.
        if (status == "new") {
          this.hideLoadingDialog();
        }
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetHrdcEmailDetails.fetch();
  },
  methods: {
    // Drag & Drop file function.
    addDropFile(e, key) {
      let file = e.dataTransfer.files[0];

      if (!file.type.match("pdf.*")) {
        this.errorField[key] = "Please select pdf file format";
        return;
      }

      this.formData[key] = file;

      switch (key) {
        case "confirmationLetterUrl":
          this.scanPdf(true);
          break;

        default:
          break;
      }
    },
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    autoEmbedData(manualSubscriptionDateRange = null) {
      // Get the selected training start & end date.
      let trainingStartDate, trainingEndDate;
      if (manualSubscriptionDateRange) {
        [trainingStartDate, trainingEndDate] = manualSubscriptionDateRange;
      } else {
        let trainingDate = this.formData.subscriptionDateRange;
        if (typeof trainingDate == "string") {
          trainingDate = trainingDate.split(" - ");
          [trainingStartDate, trainingEndDate] = trainingDate;
        } else {
          [trainingStartDate, trainingEndDate] =
            this.formData.subscriptionDateRange;
        }
      }

      if (trainingStartDate > trainingEndDate) {
        const dateErrorMsg = "Start Date cannot greater than End Date";
        this.errorField.subscriptionDateRange = dateErrorMsg;
        return;
      }

      this.errorField.subscriptionDateRange = null;

      this.apiAutoEmbedData.setParams({
        applicationUuid: this.$route.query._ausk,
        trainingStartDate,
        trainingEndDate,
        companyAddress: this.formData.companyAddress,
        companyAddressFontSize: this.formData.companyAddressFontSize,
        confirmationLetterCompanyName: this.formData.oriCompanyName,
      });

      this.apiAutoEmbedData.setCallbackCompleted((response) => {
        try {
          const { status, data } = response;

          if (!status) {
            this.$store.dispatch("showMessage", {
              message: data,
              messageType: "error",
              timeout: 2000,
            });

            this.formData.confirmationLetterUrl = null;
            this.formData.sendT3Url = null;
            this.formData.sendJd14Url = null;
            return;
          }

          if (status) {
            for (const file of response.data) {
              let blob = null;
              for (const item in file) {
                const fileItem = file[item];

                blob = new Blob([new Uint8Array(fileItem)]);

                switch (item) {
                  case "jd14":
                    this.formData.sendJd14Url = new File([blob], `JD14.pdf`, {
                      type: "application/pdf",
                    });
                    break;
                  case "t3":
                    this.formData.sendT3Url = new File([blob], `T3.pdf`, {
                      type: "application/pdf",
                    });
                    break;
                  case "confirmationLetter":
                    this.formData.confirmationLetterUrl = new File(
                      [blob],
                      `Confirmation Letter.pdf`,
                      {
                        type: "application/pdf",
                      }
                    );
                    break;
                }
              }
            }
          }
        } catch (err) {
          console.log(err);
        }
      });

      if (
        this.formData.companyAddress &&
        this.formData.oriCompanyName &&
        trainingStartDate &&
        trainingEndDate
      ) {
        this.apiAutoEmbedData.fetch();
      }
    },
    transformEmailDetails(data) {
      const emailData = {
        toEmail: [],
        ccEmail: [],
      };

      const { ApplicationAttachment, ApplicationEmail, ApplicationLog } = data;

      const ApplicationLogSubscriptionDateScheduledObj = ApplicationLog.find(
        (item) =>
          item.stageId == this.hrdcData.stagePriority.subscriptionDateScheduled
      );

      const ApplicationLogSentEmailConfirmationObj = ApplicationLog.find(
        (item) =>
          item.stageId ==
          this.hrdcData.stagePriority.sentConfirmationEmailToClient
      );

      const ApplicationEmailObj = ApplicationEmail.find(
        (item) =>
          item.stageId ==
          this.hrdcData.stagePriority.sentConfirmationEmailToClient
      );

      emailData.remarks =
        ApplicationLogSentEmailConfirmationObj.remarks == "null"
          ? null
          : ApplicationLogSentEmailConfirmationObj.remarks;

      // Get Email Recipient (replace with what admin has updated).
      const toEmailArr = [
        ...data.companyPicDetail.map((item) => item.picEmail),
      ];

      const ccEmailArr = [data.referByEmail, ...data.csData];

      this.formData.toEmail = toEmailArr.filter(
        (item, index, self) => item !== null && self.indexOf(item) == index
      );

      this.formData.ccEmail = ccEmailArr.filter(
        (item, index, self) => item !== null && self.indexOf(item) == index
      );

      for (const email of this.formData.toEmail) {
        emailData.toEmail.push(email);
      }

      for (const email of this.formData.ccEmail) {
        emailData.ccEmail.push(email);
      }

      // Assign email subject.
      emailData.emailSubject = ApplicationEmailObj.emailSubject.toUpperCase();

      // Assign company address & font size.
      emailData.companyAddress = data.companyAddress;
      emailData.companyAddressFontSize = 10;

      //  Assign course start & end date.
      const courseStartDate =
        ApplicationLogSubscriptionDateScheduledObj.startDate;
      const courseEndDate = ApplicationLogSubscriptionDateScheduledObj.endDate;

      emailData.subscriptionDateRange = [
        moment(courseStartDate, "YYYY-MM-DD HH:mm:ssZ").format("YYYY-MM-DD"),
        moment(courseEndDate, "YYYY-MM-DD HH:mm:ssZ").format("YYYY-MM-DD"),
      ];

      // Assign Send Email byPass value to true.
      emailData.byPassEmailBool = "true";

      // Assign auto capture grant approval email.
      emailData.autoCaptureGrantApprovalEmail = data.grantEmailHook
        ? "true"
        : "false";

      // Assign Company name to confirmation letter.
      emailData.oriCompanyName = data.oriCompanyName;

      // Assign attachment.
      for (const item of ApplicationAttachment) {
        // Create a Blob object from the data
        const blob = new Blob([new Uint8Array(item.dataArray)]);

        // Create the File object
        const file = new File([blob], item.fileName, {
          type: "application/pdf",
        });

        switch (item.type) {
          case "JD14":
            emailData.sendJd14Url = file;
            break;
          case "T3":
            emailData.sendT3Url = file;
            break;
          case "Confirmation Email":
            emailData.confirmationLetterUrl = file;
            break;
        }
      }

      return emailData;
    },
    scanPdf($event) {
      if ($event) {
        this.showLoadingDialog();
        const fd = new FormData();
        fd.append("confirmationLetterUrl", this.formData.confirmationLetterUrl);

        this.apiScanPdfText.setParams(fd);
        this.apiScanPdfText.setCallbackCompleted((response) => {
          try {
            if (response.status) {
              // Split the original date range using the hyphen as a delimiter
              const dateParts = response.data.split(/[\s–-]+/);

              // Format the date parts into the desired format
              const startDateParts = dateParts[0].trim().split("/");
              const endDateParts = dateParts[1].trim().split("/");

              const startDate = `${startDateParts[2]}-${startDateParts[1]}-${startDateParts[0]}`;
              const endDate = `${endDateParts[2]}-${endDateParts[1]}-${endDateParts[0]}`;

              // Construct the new formatted date range
              const formattedDateRange = [startDate, endDate];
              this.formData.subscriptionDateRange = formattedDateRange;
            } else {
              this.$store.dispatch("showMessage", {
                message: response.data,
                messageType: "error",
                timeout: 2000,
              });
            }
            this.hideLoadingDialog();
          } catch (err) {
            console.log(err);
          }
        });
        this.apiScanPdfText.fetch();
      }
    },
    previewAutoEmbedPdf(fileType) {
      if (!this.formData[fileType]) {
        this.$store.dispatch("showMessage", {
          message: "No Preview Available",
          messageType: "info",
        });
        return;
      }

      let url = null;
      let newWindow = null;
      switch (fileType) {
        default:
          url = URL.createObjectURL(this.formData[fileType]);
          newWindow = window.open(url, "_blank");
          break;
      }

      if (newWindow) {
        newWindow.onload = function () {
          // Revoke the object URL when the window is closed
          newWindow.addEventListener("beforeunload", function () {
            URL.revokeObjectURL(url);
          });
        };
      }
    },
    toggleSubscriptionDateInput() {
      this.applyGrantHint = null;
      this.formData.subscriptionDateRange = [];
      this.toggleSubscriptionDate = !this.toggleSubscriptionDate;
    },
    capitalizeFirstLetter(str) {
      return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    modalSendHrdcEmailToClient() {
      this.sendEmailDialog = !this.sendEmailDialog;
      this.submit();
    },
    generateDynamicSubscriptionDate() {
      // Get the total days need to assign.
      const daysToCount = this.priceToDays();

      const result = [];
      const oneDay = 24 * 60 * 60 * 1000;
      const now = new Date();
      const formattedDate = now.toISOString().split("T")[0];
      let validDate = new Date(formattedDate);
      validDate.setDate(validDate.getDate() + 1);
      let loopCount = 0;
      let iterations = 8;

      while (loopCount < iterations) {
        let rangeEnd = new Date(validDate);
        let isSundayInRange = false;

        // Find the range end date, checking for Sundays
        for (let i = 0; i < daysToCount - 1; i++) {
          rangeEnd = new Date(rangeEnd.getTime() + oneDay);
          if (rangeEnd.getDay() === 0) {
            // 0 is Sunday
            isSundayInRange = true;
            break;
          }
        }

        // Skip this range if it contains a Sunday
        if (!isSundayInRange) {
          const formattedStart = validDate.toISOString().split("T")[0];
          const formattedEnd = rangeEnd.toISOString().split("T")[0];

          result.push(`${formattedStart} - ${formattedEnd}`);
          loopCount++;
        }

        // Move to the next day
        validDate = new Date(validDate.getTime() + oneDay);
        if (validDate.getDay() === 0) {
          // Skip Sunday
          validDate = new Date(validDate.getTime() + oneDay);
        }
      }

      return result;
    },
    changeSubscriptionDate() {
      if (this.formData.subscriptionDateRange) {
        this.displayApplyGrantHint = true;
      }

      const dateArr = this.formData.subscriptionDateRange.split(" - ");
      this.applyGrantHint = `*Apply grant latest by ${this.formatLatestValidApplyGrantDate(
        dateArr[0]
      )}`;
      this.formData.subscriptionDateRange = `${dateArr[0]} - ${dateArr[1]}`;

      this.autoEmbedData(dateArr);
    },
    priceToDays() {
      return Math.ceil(
        this.hrdcData.getTaxpodBasePricing(this.formData.claimAmount) /
          this.hrdcData.hrdcPerDayClaim
      );
    },
    formatLatestValidApplyGrantDate(date) {
      let dateObj = new Date(date);

      // Subtract 1 day
      dateObj.setDate(dateObj.getDate() - 1);

      // Format the date as "10 Sept 2024"
      const options = { day: "numeric", month: "short", year: "numeric" };
      return dateObj.toLocaleDateString("en-GB", options);
    },
    convertSubscriptionDateRange(dateRange) {
      if (dateRange) {
        let formatToCheck = " - ";

        // If found index of ' - ', then split it to comma format.
        if (dateRange.indexOf(formatToCheck) !== -1) {
          let dateRangeSplitArr = dateRange.split(formatToCheck);
          return `${dateRangeSplitArr[0]},${dateRangeSplitArr[1]}`;
        }
      }
      return dateRange;
    },
    redirectBack() {
      let route = "HrdcApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state.hrdc.data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    async submit() {
      this.showLoadingDialog();
      // Generate Form Data to handle multipart/form-data
      const fd = new FormData();
      fd.append("applicationUuid", this.$route.query._ausk);
      fd.append("toEmail", this.formData.toEmail);
      fd.append("ccEmail", this.formData.ccEmail);
      fd.append("emailSubject", this.formData.emailSubject);

      // Subscription Date checking.
      let subscriptionDate = this.formData.subscriptionDateRange;
      if (this.inputDisabled) {
        // If TBOs resend, ensure must fill in before send.
        // Check whether the selected date is inside of dynamic generate date range or not.
        if (!this.dynamicSubscriptionDateItems.includes(subscriptionDate)) {
          subscriptionDate = null;
        }
      }

      fd.append(
        "subscriptionDateRange",
        this.convertSubscriptionDateRange(subscriptionDate)
      );
      fd.append("confirmationLetterUrl", this.formData.confirmationLetterUrl);
      fd.append("sendJd14Url", this.formData.sendJd14Url);
      fd.append("sendT3Url", this.formData.sendT3Url);
      fd.append("referById", this.auth.uuid);
      fd.append("referByName", this.auth.name);
      fd.append("referByEmail", this.auth.email);
      fd.append(
        "referByMobile",
        this.auth.mobile ?? this.hrdcData.yycMobileHotline
      );
      fd.append("remarks", this.formData.remarks);
      fd.append("editMode", this.editMode);
      fd.append("byPassEmailBool", this.formData.byPassEmailBool);
      fd.append("companyAddress", this.formData.companyAddress);
      fd.append(
        "autoCaptureGrantApprovalEmail",
        this.formData.autoCaptureGrantApprovalEmail
      );
      fd.append("nextStageId", this.nextStageId);

      this.apiSubmit.setParams(fd);
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.hideLoadingDialog();
          if (!response.status) {
            this.showErrorMessage(response);
            if (response.applicationNotFound) {
              this.$store.dispatch("showMessage", {
                message: response.data.applicationNo,
                messageType: "error",
                timeout: 2000,
              });
            }
          }
          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: "HrdcApplication",
              query: {
                viewId: this.$store.state.hrdc.data.viewId,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
    },
  },
};
</script>
<style>
.apply-grant-hint
  .v-input__control
  .v-text-field__details
  .v-messages__message {
  color: red !important;
  font-style: italic;
  font-size: 14px;
}
</style>
